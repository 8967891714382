import { apiService } from '../../services/api-service';
import { getStaticAssetPath } from '../../utils/assets';

export class CodeTemplateUtils {
  static CODE_FILENAME = 'index.html';

  static GetCodeS3Url(id: string) {
    return getStaticAssetPath(`code-templates/${id}/${this.CODE_FILENAME}`);
  }

  static async FetchCode(id: string): Promise<string> {
    const url = this.GetCodeS3Url(id);

    const response = await fetch(url);
    if (!response.ok) {
      if (response.status === 404 || response.status === 403) {
        return '';
      } else {
        throw new Error(`Failed to fetch code: ${response.status}`);
      }
    }
    const fetchedCode = await response.text();
    return fetchedCode;
  }

  static async UploadCode(id: string, code: string) {
    await apiService.codeTemplate.uploadFile(
      id,
      new Blob([code], { type: 'text/html' }),
      {
        filename: this.CODE_FILENAME,
      }
    );
  }
}
