import { useLoaderData, useRevalidator } from '@remix-run/react';

import { type ModelsCodeTemplate } from '@lp-lib/api-service-client/public';

import { CodeTemplateTable } from '../components/CodeTemplate/CodeTemplateTable';
import { useLiveCallback } from '../hooks/useLiveCallback';
import { useTitle } from '../hooks/useTitle';
import { AdminView } from '../pages/Admin/AdminView';
import { AdminToolkitNav } from '../pages/Admin/Toolkit';
import { apiService } from '../services/api-service';
import { makeTitle } from '../utils/common';

export async function clientLoader() {
  const resp = await apiService.settings.getData('code-templates');

  const templates = Object.values(resp.data.data.templates).sort((a, b) => {
    if (a.enabled !== b.enabled) {
      return a.enabled ? -1 : 1;
    }
    return a.name.localeCompare(b.name);
  });

  return {
    templates,
  };
}

export function Component() {
  const { templates } = useLoaderData<typeof clientLoader>();
  const revalidate = useRevalidator();

  useTitle(makeTitle('Code Templates'));

  const handleSave = useLiveCallback(
    async (templates: ModelsCodeTemplate[]) => {
      const mp = new Map<string, ModelsCodeTemplate>();
      for (const t of templates) {
        mp.set(t.id, t);
      }
      await apiService.settings.updateValue('code-templates', {
        key: 'templates',
        value: Object.fromEntries(mp),
      });

      revalidate.revalidate();
    }
  );

  return (
    <AdminView className='bg-library-2023-07 p-10 flex flex-col gap-10'>
      <AdminToolkitNav />
      <CodeTemplateTable templates={templates} onSave={handleSave} />
    </AdminView>
  );
}
