import { type ModelsCodeTemplate } from '@lp-lib/api-service-client/public';

import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { DeleteIcon } from '../icons/DeleteIcon';
import { PlusIcon } from '../icons/PlusIcon';
import { useTriggerCodeTemplateEditorModal } from './CodeTemplateEditor';

export function CodeTemplateTable(props: {
  templates: ModelsCodeTemplate[];
  onSave: (templates: ModelsCodeTemplate[]) => void;
}) {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const triggerEditorModal = useTriggerCodeTemplateEditorModal();

  const handleAdd = async () => {
    await triggerEditorModal({
      onSave: (template) => {
        props.onSave([...props.templates, template]);
      },
    });
  };

  const handleEdit = async (template: ModelsCodeTemplate) => {
    await triggerEditorModal({
      template,
      onSave: (template) => {
        props.onSave(
          props.templates.map((t) => (t.id === template.id ? template : t))
        );
      },
    });
  };

  const handleDelete = async (key: string) => {
    const response = await triggerModal({
      kind: 'confirm-cancel',
      prompt: (
        <div className='text-white text-center text-2xl font-medium'>
          Are you sure you want to delete this template?
        </div>
      ),
      confirmBtnLabel: 'Delete',
      cancelBtnLabel: 'Cancel',
      autoFocus: 'cancel',
      confirmBtnVariant: 'delete',
    });
    if (response.result !== 'confirmed') return;

    const newTemplates = props.templates.filter((t) => t.id !== key);
    props.onSave(newTemplates);
  };

  return (
    <div className='text-white'>
      <div className='pb-4'>
        <div className='flex justify-between items-center'>
          <h2 className='text-2xl font-bold'>
            Code Templates{' '}
            <span className='text-icon-gray text-xl'>
              ({props.templates.length})
            </span>
          </h2>
          <button
            type='button'
            className='btn flex justify-center items-center text-primary gap-1 text-sm hover:underline'
            onClick={handleAdd}
          >
            <PlusIcon />
            Add Template
          </button>
        </div>
      </div>

      {props.templates.length === 0 ? (
        <div className='w-full py-6 flex items-center justify-center text-icon-gray'>
          No templates
        </div>
      ) : (
        <table className='w-full table-fixed border-collapse'>
          <thead>
            <tr>
              <th className='text-left font-bold py-2 border-b border-t border-secondary w-1/4'>
                Name
              </th>
              <th className='text-left font-bold py-2 border-b border-t border-secondary'>
                Description
              </th>
              <th className='text-left font-bold py-2 border-b border-t border-secondary w-25'>
                Status
              </th>
              <th className='text-left font-bold py-2 border-b border-t border-secondary w-12'></th>
            </tr>
          </thead>
          <tbody>
            {props.templates.map((template) => (
              <tr
                key={template.id}
                className='hover:bg-dark-gray cursor-pointer'
                onClick={() => handleEdit(template)}
              >
                <td className='text-left font-bold pl-3 py-3 border-b border-black-001 pr-3 truncate'>
                  <div className='flex items-center gap-2'>
                    <span>{template.name}</span>
                  </div>
                </td>
                <td className='text-left py-3 border-b border-black-001 pr-3 truncate'>
                  {template.description}
                </td>
                <td className='text-left py-3 border-b border-black-001 pr-3'>
                  {template.enabled ? 'Enabled' : 'Disabled'}
                </td>
                <td className='h-px p-0 border-b border-black-001'>
                  <div className='h-full flex items-center justify-center'>
                    <button
                      type='button'
                      className='btn text-red-002'
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(template.id);
                      }}
                    >
                      <DeleteIcon className='w-3.5 h-3.5 fill-current' />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}
