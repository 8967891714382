import { FormProvider, useController, useForm } from 'react-hook-form';

import { type ModelsCodeTemplate } from '@lp-lib/api-service-client/public';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import { uuidv4 } from '../../utils/common';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { ModalWrapper } from '../ConfirmCancelModalContext/ModalWrapper';
import { CodeTemplateUtils } from './utils';

function NameField() {
  const {
    field: { value, onChange },
    fieldState,
  } = useController<FormData, 'name'>({
    name: 'name',
    rules: { required: true, maxLength: 100 },
  });

  return (
    <div className='w-full flex flex-col gap-2'>
      <label className='font-bold'>Name (Required)</label>
      <input
        className={`${fieldState.error ? 'field-error' : 'field'}  w-full mb-0`}
        type='text'
        value={value || ''}
        onChange={onChange}
        placeholder='Must be 1 to 100 characters'
      />
    </div>
  );
}

function EnabledField() {
  const {
    field: { value, onChange },
  } = useController<FormData, 'enabled'>({
    name: 'enabled',
  });

  return (
    <div className='w-full flex flex-col gap-2'>
      <div className='font-bold'>Enabled</div>
      <div className='w-full h-10 flex items-center gap-2'>
        <input
          id='code-template-enabled'
          type='checkbox'
          checked={value}
          onChange={(e) => onChange(e.target.checked)}
          className='h-5 w-5 checkbox-dark'
        />
      </div>
    </div>
  );
}

function DescriptionField() {
  const {
    field: { value, onChange },
    fieldState,
  } = useController<FormData, 'description'>({
    name: 'description',
    rules: { required: true },
  });

  return (
    <div className='w-full flex flex-col gap-2'>
      <div>
        <p className='text-base font-bold'>Description</p>
        <p className='text-sms font-normal text-icon-gray'>
          AI can see this description and decide to use the template.
        </p>
      </div>
      <textarea
        className={`${
          fieldState.error ? 'field-error' : 'field'
        } w-full mb-0 p-2 h-15`}
        value={value || ''}
        onChange={onChange}
        placeholder="Describe the template's key features and its best use cases. E.g., 'Displays items in interactive columns, ideal for comparing products or plans.'"
      />
    </div>
  );
}

function CodeField() {
  const {
    field: { value, onChange },
    fieldState,
  } = useController<FormData, 'code'>({
    name: 'code',
    rules: { required: true },
  });

  return (
    <div className='w-full flex flex-col gap-2'>
      <div className='font-bold'>Code</div>
      <textarea
        value={value}
        onChange={onChange}
        className={`w-full h-90 field mb-0 p-2 ${
          fieldState.error ? 'field-error' : 'field'
        }`}
      />
    </div>
  );
}

interface FormData {
  name: string;
  description: string;
  enabled: boolean;
  code: string;
}

export function CodeTemplateEditor(props: {
  title: string;
  defaultValues: FormData;
  onSave: (values: FormData) => Promise<void>;
  onCancel: () => void;
}) {
  const { defaultValues, onSave, onCancel } = props;

  const form = useForm<FormData>({
    defaultValues,
  });

  return (
    <FormProvider {...form}>
      <form
        className='w-full h-full flex flex-col items-center gap-8'
        onSubmit={form.handleSubmit(async (data) => {
          await onSave(data);
        })}
      >
        <div className='font-bold text-2xl'>{props.title}</div>

        <div className='w-full flex flex-col justify-center items-center gap-4'>
          <div className='w-full grid grid-cols-2 gap-8 items-end'>
            <NameField />
            <EnabledField />
          </div>
          <DescriptionField />
          <CodeField />
        </div>

        <div className='w-full flex flex-row justify-center items-center gap-2'>
          <button
            className='btn-secondary w-34 h-10 flex flex-row justify-center items-center'
            type='button'
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            className='btn-primary w-34 h-10 flex flex-row justify-center items-center'
            type='submit'
            disabled={form.formState.isSubmitting}
          >
            {form.formState.isSubmitting ? 'Saving...' : 'Save'}
          </button>
        </div>
      </form>
    </FormProvider>
  );
}

export function useTriggerCodeTemplateEditorModal() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  return useLiveCallback(
    async (props: {
      template?: ModelsCodeTemplate;
      onSave: (template: ModelsCodeTemplate) => void;
    }) => {
      let code = '';
      if (props.template) {
        code = await CodeTemplateUtils.FetchCode(props.template.id);
      }

      triggerModal({
        kind: 'custom',
        element: (p) => (
          <ModalWrapper
            borderStyle='gray'
            containerClassName='w-3/4 max-w-[1280px]'
            innerClassName='p-10'
          >
            <CodeTemplateEditor
              title={props.template ? 'Edit Template' : 'Create Template'}
              defaultValues={{
                name: props.template?.name || '',
                description: props.template?.description || '',
                enabled: props.template?.enabled ?? true,
                code: code,
              }}
              onSave={async (values) => {
                const template: ModelsCodeTemplate = {
                  id: props.template?.id || uuidv4(),
                  ...values,
                };

                if (code !== values.code) {
                  await CodeTemplateUtils.UploadCode(template.id, values.code);
                }

                props.onSave(template);
                p.internalOnConfirm();
              }}
              onCancel={p.internalOnCancel}
            />
          </ModalWrapper>
        ),
      });
    }
  );
}
